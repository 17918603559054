import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from "../components/layout"
import Title from '../components/styles/Title'

export default function Privacy({location}) {

    return (
        <Layout>
            <GatsbySeo canonical={location.href} title="NaturalVoicer Privacy Policy" />
            <div className="bg-light">
                <div className="mx-auto max-w-[1000px] px-4 w-full pt-[124px] pb-20">
                    <div className="flex justify-between xl:flex-col">
                        <ul className="w-[216px] sticky top-16 left-0 h-full font-bold xl:hidden">
                            <li className="text-blue relative after:w-[4px] after:content-none after:bg-blue after:absolute after:-left-2 after:top-0 after:h-full"><a href="#" className="py-3 block border-t border-gray-80">Privacy Policy</a></li>
                            <li><a href="#" className="py-3 block border-t border-gray-80">Cookies</a></li>
                            <li><a href="#" className="py-3 block border-t border-gray-80">Updates</a></li>
                        </ul>
                        <div className="w-[calc(100%-240px)] bg-white shadow-block px-[30px] pt-10 pb-20 xl:w-full sm:px-4">
                            <h1 css={Title("h1")} className="pb-5">Privacy Policy</h1>
                            <p className="pb-5">NaturalVoicer Technology Co., Ltd is committed to respecting and safeguarding your privacy. By using our website, service or software, you agree to follow our Privacy Policy each time you reach our website. NaturalVoicer is the data controller of the personal data stored on this website according to this Private Policy.</p>
                            <p className="pb-5">This page will inform users of the details about what data we collect, why we collect it and what we do with it. Please read the follow Private Policy carefully.</p>
                            <p className="pb-5">This Privacy Policy is effective from Sept, 20, 2020.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">What Do We Collect from You</h2>
                            <p className="pb-5">To provide better experience for you, we collect a few different types of information from you when you come our website or use our service and software. There are three broad categories we collect: the information you provide to us, the information collected automatically and the information we gather from other source.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">Information You Provide to Us</h2>
                            <p className="pb-5">NaturalVoicer collects information you provide to use, which including:</p>
                            <ul className="list-dot">
                                <li>Subscribe Information: When you subscribe to our newsletter, we may ask for your name, email address, mailing address, phone number and other info you may need to provide. NaturalVoicer will use this to improve our service.</li>
                                <li>Information You Post on NaturalVoicer: When you post a review, ask for some question from our online form, we will gather your name, email address, comments, likes, interests, status, pictures and other possible data.</li>
                                <li>Billing and Payment Information: When you place an order from NaturalVoicer, we will obtain your phone number, credit card number, credit card expiration date and others. This is used to the bill and payment and is 100% safe.</li>
                                <li>Location Information: We may also collect the geographic area when you use a computer or a mobile to visit our website or use our software.</li>
                            </ul>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">Information Collected Automatically</h2>
                            <p className="pb-5">Apart from the information you provide to us, our website may also collect some of your personal data automatically. This includes:</p>
                            <ul className="list-dot">
                                <li>Time and date of use</li>
                                <li>Operations you have process</li>
                                <li>You location and device info</li>
                                <li>IP address, browser type and settings</li>
                                <li>Information about browser configuration</li>
                                <li>Language preferences, and cookie data</li>
                            </ul>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">Information We Gather from Other Source</h2>
                            <p className="pb-5">This kind of information is from our third-party partners or other social platform which you allow to share your personal data.</p>
                            <ul className="list-dot">
                                <li>Response your questions about our software</li>
                                <li>Provide identification to complete an order or service</li>
                                <li>Provide support to your requests, comments or other requirements</li>
                                <li>Send you the latest new, updates or sales</li>
                                <li>Perform a website used data analytics</li>
                                <li>Comply with legal obligations</li>
                            </ul>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">How We Save and Protect Your Information</h2>
                            <p className="pb-5">We pay attention to every user's privacy security. We use appropriate physical, management, and technical measures to protect your information from accidental, unlawful, or unauthorized destruction, loss, alteration, access, disclosure, or use.</p>
                            <p className="pb-5">NaturalVoicer will not sell, rent, or trade your personal data to anyone.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">Do We Share or Disclose Your Data</h2>
                            <p className="pb-5">We may share your information to our agents, consultants, and other service providers on our behalf. Or to the third parties you have allowed to use your data. However, they are obligated not to disclose or use the information for any other purpose.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">Children's Information</h2>
                            <p className="pb-5">NaturalVoicer is not intended to collect information for children under 16 years old. If you are or have a child under the age of 16, please contact us and we will delete any of his/her personal data.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">Cookies</h2>
                            <p className="pb-5">Cookies are small pieces of text containing a string of alphanumeric characters which are stored on the browser or your device when you visit a website or application. A cookie helps the website to remember information about your visit, like your preferences, so that the next visit will become easier. Cookies play an important role in improving your experience browsing our websites.</p>
                            <h2 className="pt-[30px] text-2xl pb-5 font-raleway font-extrabold">Updates</h2>
                            <p>NaturalVoicer will update this Private Policy from time to time, you can always review this page when you visit our website.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

