import tw, { styled } from 'twin.macro'

const Title = (variant) => [
    // The common button styles added with the tw import
    tw`font-raleway font-black leading-snug`,
    variant === 'h1' && tw`text-[42px] lg:text-[calc(18px + 2vw)]`,
    variant === 'h2' && tw`text-[40px] lg:text-[calc(18px + 1.8vw)] text-center`
  ]

export default Title
